import React, { useState, useEffect } from "react";
import {
  Grid,
  CircularProgress,
  Typography,
  Button,
  Tabs,
  Tab,
  TextField,
} from "@mui/material";
import { withRouter } from "react-router-dom";
import useStyles from "./styles";
import smalllogo from "./SpaceAge-Icon-Grayscale.png";
import { useUserDispatch, loginUser } from "../../context/UserContext";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { usePageTracking } from "./../../usePageTracking";
import { whitelabelConfig } from "../../whitelabelConfig/whitelabelConfig";
import { useDispatch, useSelector } from "react-redux";
import { resetUserPassword } from "../../app/resetPasswordSlice";

function Login(props) {
  const classes = useStyles();
  const userDispatch = useUserDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [activeTabId, setActiveTabId] = useState(0);
  const [loginValue, setLoginValue] = useState("");
  const [passwordValue, setPasswordValue] = useState("");
  const [imgLogo, setimgLogo] = useState(null);
  const [labelName, setlabelName] = useState("TOP Clear");
  const [logoWidth, setlogoWidth] = useState(70);
  const [logoHeight, setlogoHeight] = useState(80);
  var [reseterror] = useState(null);
  var [resetsuccess] = useState(null);
  const [resetPasswordEmail, setResetPasswordEmail] = useState("");
  const [resetPasswordOrganization, setResetPasswordOrganization] =
    useState("");
  const [resetPasswordResponse, setResetPasswordResponse] = useState("");
  const [messageOpen, setMessageOpen] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  var [errorMsg] = useState("");
  const [forgotPasswordStatus, setForgotPasswordStatus] = useState(false);

  const vertical = "top";
  const horizontal = "right";
  const dispatch = useDispatch();

  usePageTracking();

  useEffect(() => {
    let urlName = new URL(window.location.href);
    urlName = urlName.hostname;
    sessionStorage.setItem("siteDomain", urlName);
    if (whitelabelConfig[urlName] && whitelabelConfig[urlName] !== undefined) {
      setimgLogo(whitelabelConfig[urlName].logo);
      setlogoHeight(whitelabelConfig[urlName].logoHeight);
      setlogoWidth(whitelabelConfig[urlName].logoWidth);
      setlabelName(whitelabelConfig[urlName].name);
    } else {
      setimgLogo(smalllogo);
      setlabelName("TOP Clear");
      setlogoWidth(70);
      setlogoHeight(80);
    }
    document.title = `TOP Clear - Login`;
  }, []);

  const resetPasswordStatus = useSelector(
    (state) => state.resetPasswordSlice.resetPasswordStatus
  );
  useEffect(() => {
    if (resetPasswordStatus) {
      setResetPasswordResponse(resetPasswordStatus);
      setErrorOpen(false);
      setMessageOpen(true);
      setForgotPasswordStatus(false);
      setIsLoading(false);
    }
  }, [resetPasswordStatus]);

  const resetPasswordError = useSelector(
    (state) => state.resetPasswordSlice.resetPasswordError
  );

  useEffect(() => {
    if (resetPasswordError === "Request failed with status code 400") {
      setResetPasswordResponse("Invalid Organization Details, Try Again");
      setMessageOpen(false);
      setErrorOpen(true);
      setIsLoading(false);
    } else if (resetPasswordError) {
      setResetPasswordResponse("Invalid Details, Try Again");
      setMessageOpen(false);
      setErrorOpen(true);
      setIsLoading(false);
    }
  }, [resetPasswordError]);

  return (
    <Grid container className={classes.container}>
      <Grid
        className={classes.logotypeContainer}
        item
        lg={7}
        md={7}
        sm={6}
        xs={12}
      >
        {imgLogo !== null && (
          <img
            src={imgLogo}
            alt="logo"
            style={{ width: logoWidth, height: logoHeight }}
            className={classes.smalllogotypeImage}
          />
        )}
        {imgLogo !== null && (
          <div className={classes.logotypeImage}>
            <Typography
              variant="h5"
              weight="bold"
              style={{
                fontSize: 50,
                fontFamily: "Poppins",
                textAlign: "center",
                color: "#daefff",
              }}
              className={classes.logotype}
            >
              {" "}
              {labelName}{" "}
            </Typography>
            {/* <Typography variant="h5" weight="bold" style={{ fontSize : 50, fontFamily :'Poppins', textAlign : 'center', color : '#ffffff'}} className={classes.logotype}> GIS Platform  </Typography> */}
          </div>
        )}
      </Grid>
      <Grid className={classes.formContainer} item lg={5} md={5} sm={6} xs={12}>
        <div className={classes.root}>
          <div>
            <Snackbar
              anchorOrigin={{ vertical, horizontal }}
              open={error}
              autoHideDuration={3000}
            >
              <Alert severity="error">Login Failed, Invalid Credentials</Alert>
            </Snackbar>
          </div>
          <div>
            <Snackbar
              anchorOrigin={{ vertical, horizontal }}
              open={resetsuccess}
              autoHideDuration={3000}
            >
              <Alert severity="success">
                Reset Password Email Sent Successfully
              </Alert>
            </Snackbar>
          </div>
          <div>
            <Snackbar
              anchorOrigin={{ vertical, horizontal }}
              open={reseterror}
              autoHideDuration={3000}
            >
              <Alert severity="error">{errorMsg}</Alert>
            </Snackbar>
          </div>
          <div>
            <Snackbar
              anchorOrigin={{ vertical, horizontal }}
              open={messageOpen}
              autoHideDuration={3000}
              onClose={() => setMessageOpen(false)}
            >
              <Alert severity="success">{resetPasswordResponse}</Alert>
            </Snackbar>
            <Snackbar
              anchorOrigin={{ vertical, horizontal }}
              open={errorOpen}
              autoHideDuration={3000}
              onClose={() => setErrorOpen(false)}
            >
              <Alert severity="error">{resetPasswordResponse}</Alert>
            </Snackbar>
          </div>
        </div>
        <div className={classes.form}>
          <Tabs
            value={activeTabId}
            onChange={(e, id) => setActiveTabId(id)}
            indicatorColor="primary"
            textColor="primary"
            centered
            style={{ marginBottom: "10%" }}
          >
            <Tab label="Login" classes={{ root: classes.tab }} />
          </Tabs>
          {forgotPasswordStatus === false && (
            <React.Fragment>
              <TextField
                id="email"
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                value={loginValue}
                onChange={(e) => setLoginValue(e.target.value)}
                margin="normal"
                placeholder="Email Address"
                type="email"
                fullWidth
              />
              <TextField
                id="password"
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                value={passwordValue}
                onChange={(e) => setPasswordValue(e.target.value)}
                margin="normal"
                placeholder="Password"
                type="password"
                fullWidth
                onKeyUp={(event) => {
                  if (event.key === "Enter") {
                    loginUser(
                      userDispatch,
                      loginValue,
                      passwordValue,
                      props.history,
                      setIsLoading,
                      setError
                    );
                  }
                }}
              />
              <div className={classes.formButtons}>
                {isLoading ? (
                  <CircularProgress size={26} className={classes.loginLoader} />
                ) : (
                  <Button
                    disabled={
                      loginValue.length === 0 || passwordValue.length === 0
                    }
                    onClick={() =>
                      loginUser(
                        userDispatch,
                        loginValue,
                        passwordValue,
                        props.history,
                        setIsLoading,
                        setError
                      )
                    }
                    variant="contained"
                    color="primary"
                    size="large"
                  >
                    Login
                  </Button>
                )}
                <Button
                  color="primary"
                  size="large"
                  className={classes.forgetButton}
                  onClick={() => setForgotPasswordStatus(true)}
                >
                  Forgot Password?
                </Button>
              </div>
            </React.Fragment>
          )}
          {forgotPasswordStatus === true && (
            <React.Fragment>
              <Typography color="primary" className={classes.errorMessage}>
                Enter your E-mail address, we will send you a link to reset your
                password.
              </Typography>
              <TextField
                id="email"
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                value={resetPasswordEmail}
                onChange={(e) => setResetPasswordEmail(e.target.value)}
                margin="normal"
                placeholder="Email Address"
                type="email"
                fullWidth
              />
              <TextField
                id="password"
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                value={resetPasswordOrganization}
                onChange={(e) => setResetPasswordOrganization(e.target.value)}
                margin="normal"
                placeholder="Org Name"
                fullWidth
              />
              <div className={classes.formButtons}>
                {isLoading ? (
                  <CircularProgress size={26} className={classes.loginLoader} />
                ) : (
                  <Button
                    disabled={
                      resetPasswordEmail.length === 0 ||
                      resetPasswordOrganization.length === 0
                    }
                    onClick={() => {
                      dispatch(
                        resetUserPassword({
                          email: resetPasswordEmail,
                          organization: resetPasswordOrganization,
                        })
                      );
                      setIsLoading(true);
                    }}
                    variant="contained"
                    color="primary"
                    size="large"
                  >
                    Send
                  </Button>
                )}
                <Button
                  color="primary"
                  size="large"
                  className={classes.forgetButton}
                  onClick={() => setForgotPasswordStatus(false)}
                >
                  Back to Login
                </Button>
              </div>
            </React.Fragment>
          )}
        </div>
        <Typography color="primary" className={classes.copyright}>
          &copy; Powered by Turing
        </Typography>
      </Grid>
    </Grid>
  );
}

export default withRouter(Login);
