import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Menu,
  MenuItem,
  Box,
} from "@mui/material";
import {
  Menu as MenuIcon,
  MailOutline as MailIcon,
  NotificationsNone as NotificationsIcon,
  Person as AccountIcon,
  Search as SearchIcon,
  Send as SendIcon,
  ArrowBack as ArrowBackIcon,
  AccountCircle,
} from "@mui/icons-material";
import classNames from "classnames";

import { useMediaQuery, useTheme } from "@mui/material";

// styles
import useStyles from "./styles";

// components
import { Badge, Typography, Button } from "../Wrappers/Wrappers";
import Notification from "../Notification/Notification";
import UserAvatar from "../UserAvatar/UserAvatar";
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from "../../context/LayoutContext";
import { useUserDispatch, signOut } from "../../context/UserContext";
import axios from "axios";
import moment from "moment";
//import MaterialTable from 'material-table';
import { useParams, useHistory } from "react-router-dom";
import RebrandingBanner from "../RebrandingBanner/RebrandingBanner";
import logo from "./Logo-TOP Clear Teal.png";
import { styled } from "@mui/material/styles";

function createAudit(keyId, user, eventType, content, object, logTime) {
  if (eventType == "updated" && content == "user") {
    return {
      id: keyId,
      type: "notification",
      color: "secondary",
      message:
        user +
        " " +
        "logged in at " +
        moment(logTime).format("DD-MMM-YYYY hh:mm A"),
    };
  } else if (eventType == "many-to-many changed") {
    return {
      id: keyId,
      type: "notification",
      color: "secondary",
      message:
        user +
        " " +
        "updated " +
        content +
        " " +
        object +
        " at " +
        moment(logTime).format("DD-MMM-YYYY hh:mm A"),
    };
  } else {
    return {
      id: keyId,
      type: "notification",
      color: "secondary",
      message:
        user +
        " " +
        eventType +
        " " +
        content +
        " " +
        object +
        " at " +
        moment(logTime).format("DD-MMM-YYYY hh:mm A"),
    };
  }
}
function createuserData(firstName, lastName, email, role) {
  return { firstName: firstName, lastName: lastName, user: email, role: role };
}
export default function EnterpriseHeader(props) {
  var classes = useStyles();
  const history = useHistory();

  // global
  var layoutState = useLayoutState();
  var layoutDispatch = useLayoutDispatch();
  var userDispatch = useUserDispatch();

  // local
  var [mailMenu, setMailMenu] = useState(null);
  var [isMailsUnread, setIsMailsUnread] = useState(true);
  var [notificationsMenu, setNotificationsMenu] = useState(null);
  var [isNotificationsUnread, setIsNotificationsUnread] = useState(true);
  var [profileMenu, setProfileMenu] = useState(null);
  var [isSearchOpen, setSearchOpen] = useState(false);
  const [isSalAdmin, setisSalAdmin] = React.useState("");
  const [auditMsg, setauditMsg] = React.useState([]);
  const [isSalReadonly, setiisSalReadonly] = React.useState("");
  const [enterpriseName, setenterpriseName] = React.useState("");
  const defaultLogoImg = `${process.env.PUBLIC_URL}/icon.png`;
  const [logoImage, setlogoImage] = useState(defaultLogoImg);
  const username = sessionStorage.getItem("email");
  //const [logoWidth, setlogoWidth] = useState(41);
  //const [logoHeight, setlogoHeight] = useState(40);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md")); // Hides for md and below

  const Logo = styled("img")({
    width: 130,
    // marginBottom: 8,
  });

  useEffect(() => {
    var adminState = `${sessionStorage.getItem("isSalAdmin")}` == "true";
    setisSalAdmin(adminState);
    var salReadOnly = `${sessionStorage.getItem("isReadOnly")}` == "true";
    setiisSalReadonly(salReadOnly);
    var readOnlyState = `${sessionStorage.getItem("isReadOnly")}`;

    axios
      .get(
        `/${sessionStorage.getItem(
          "organization"
        )}/enterprise-org/${sessionStorage.getItem("organization")}`
      )
      .then((res) => {
        let userData = res.data.name;
        if (userData) {
          setenterpriseName("" + userData);
        } else {
          setenterpriseName("");
        }
      })
      .catch((err) => {
        console.log(err);
      });
    if (readOnlyState == "false") {
      axios
        .get(`/${sessionStorage.getItem("organization")}/auditlog?limit=10`)
        .then((res) => {
          let userData = res.data.results;
          var tempArr = [];
          var keyId = 0;
          userData.map((item, index) => {
            var eventType = "";
            keyId = keyId + 1;
            eventType = (item.event_type + "d").toLowerCase();
            tempArr.push(
              createAudit(
                keyId,
                item.user,
                eventType,
                item.content_type,
                item.object_repr,
                item.datetime
              )
            );
          });
          setauditMsg(tempArr);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setauditMsg([]);
    }
  }, []);
  // function handleNavButton() {
  //   setNotificationsMenu(null)
  //   history.push('/app/UserActivity')
  //  }
  function openProfile() {
    axios
      .get(
        `/${sessionStorage.getItem(
          "organization"
        )}/users/${sessionStorage.getItem("email")}`
      )
      .then((res) => {
        let userData = res.data;
        var dataArr = [];
        dataArr = createuserData(
          userData.first_name,
          userData.last_name,
          userData.user,
          userData.role
        );
        history.push({
          pathname: "/app/UserDetails",
          state: { detail: dataArr, fromPage: "/app/Dashboard" },
        });
      });
    setProfileMenu(null);
  }

  return (
    <AppBar
      sx={{
        background:
          "linear-gradient(177deg,rgba(25,25,25,1), rgba(0,25,113, 1))",
      }}
      position="fixed"
      className={classes.appBar}
    >
      <Toolbar
        className={classes.toolbar}
        style={{ paddingLeft: isSmallScreen ? "0px" : "24px" }}
      >
        <IconButton
          color="inherit"
          onClick={() => toggleSidebar(layoutDispatch)}
          className={classNames(
            classes.headerMenuButton,
            classes.headerMenuButtonCollapse
          )}
        >
          {layoutState.isSidebarOpened ? (
            <ArrowBackIcon
              classes={{
                root: classNames(
                  classes.headerIcon,
                  classes.headerIconCollapse
                ),
              }}
            />
          ) : (
            <MenuIcon
              classes={{
                root: classNames(
                  classes.headerIcon,
                  classes.headerIconCollapse
                ),
              }}
            />
          )}
        </IconButton>
        {/* <span variant="h6" weight="medium" className={classes.logotype}>
          {enterpriseName}
        </span> */}
        <div
          style={{
            display: "flex",
            flexGrow: 1,
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div style={{ display: "flex", placeItems: "center", gap: 10 }}>
            {!isSmallScreen && (
              <Box display="flex" alignItems="center" ml={2}>
                <img
                  alt="icon"
                  src={logoImage}
                  width="auto"
                  height="42px"
                  style={{ height: 42, width: "unset" }}
                />
              </Box>
            )}
            <div
              style={{
                fontFamily: "Poppins",
                fontSize: 15,
                fontWeight: "normal",
                width: isSmallScreen ? "120px" : "auto",
              }}
            >
              {enterpriseName}
            </div>
          </div>
          <div
            style={{
              position: "absolute",
              left: "50%",
              transform: "translateX(-50%)",
              textAlign: "center",
            }}
          >
            <AppBar position="static" color="transparent" elevation={0}>
              <Logo src={logo} alt="Turing" />
            </AppBar>
          </div>

          <div>
            {/* {isSalReadonly === false ?
              <IconButton
                color="inherit"
                aria-haspopup="true"
                aria-controls="mail-menu"
                onClick={e => {
                  setNotificationsMenu(e.currentTarget);
                  setIsNotificationsUnread(false);
                }}
                className={classes.headerMenuButton}
              >
                <Badge
                  badgeContent={null}
                  color="red"
                >
                  <NotificationsIcon classes={{ root: classes.headerIcon }} />
                </Badge>
              </IconButton> : null} */}
            {/* {isSalAdmin === true ? */}
            <IconButton
              aria-haspopup="true"
              color="inherit"
              className={classes.headerMenuButton}
              aria-controls="profile-menu"
              // onClick={e => openProfile()}
              onClick={(e) => setProfileMenu(e.currentTarget)}
            >
              <AccountIcon classes={{ root: classes.headerIcon }} />
            </IconButton>

            <Menu
              id="profile-menu"
              open={Boolean(profileMenu)}
              anchorEl={profileMenu}
              onClose={() => setProfileMenu(null)}
              className={classes.headerMenu}
              classes={{ paper: classes.profileMenu }}
              disableAutoFocusItem
            >
              <div className={classes.profileMenuUser}>
                <Typography style={{ fontSize: 16 }} weight="medium">
                  {username}
                </Typography>
              </div>
              <div className={classes.profileMenuUser}>
                <div className={classes.profileMenuLinkWrapper}>
                  <Typography
                    style={{
                      marginRight: "10px",
                      fontSize: 14,
                      color: "#2d4d76",
                      fontWeight: "bold",
                    }}
                    className={classes.profileMenuLink}
                    color="primary"
                    weight="bold"
                    onClick={() => openProfile()}
                  >
                    Profile
                  </Typography>
                  <Typography
                    className={classes.profileMenuLink}
                    weight="bold"
                    color="primary"
                    onClick={() => signOut(userDispatch, history)}
                  >
                    Sign Out
                  </Typography>
                </div>
              </div>
            </Menu>

            {/* // : null} */}
            <Menu
              id="notifications-menu"
              open={Boolean(notificationsMenu)}
              anchorEl={notificationsMenu}
              onClose={() => setNotificationsMenu(null)}
              className={classes.headerMenu}
              disableAutoFocusItem
            >
              <div>
                <div
                  style={{
                    marginLeft: "5%",
                    fontSize: 18,
                    fontWeight: "bold",
                    fontFamily: "Poppins",
                    marginTop: "3%",
                    marginBottom: "3%",
                    color: "rgb(7,71,166)",
                  }}
                >
                  {" "}
                  Notifications{" "}
                </div>
              </div>
              <div style={{ width: "350px", display: "inline-block" }}>
                {auditMsg.length > 0 ? (
                  <div style={{ marginTop: "-2%", paddingLeft: "2%" }}>
                    {auditMsg.map((notification) => (
                      <MenuItem
                        key={notification.id}
                        //onClick={() => setNotificationsMenu(null)}
                        className={classes.headerMenuItem}
                      >
                        <Notification
                          {...notification}
                          typographyVariant="inherit"
                        />
                      </MenuItem>
                    ))}
                  </div>
                ) : (
                  <div style={{ marginTop: "2%", height: "240px" }}>
                    <Typography
                      style={{
                        marginTop: "5%",
                        fontWeight: "bold",
                        fontFamily: "Poppins",
                        textAlign: "center",
                        fontSize: "14px",
                      }}
                    >
                      {" "}
                      No Activity{" "}
                    </Typography>
                  </div>
                )}
              </div>
            </Menu>
          </div>
        </div>

        {/* <div className={classes.grow} /> */}
      </Toolbar>
      <RebrandingBanner />
    </AppBar>
  );
}
