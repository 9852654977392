import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  CircularProgress,
  TextField,
  Typography,
  Snackbar,
  Alert,
  AppBar,
  Divider,
} from "@mui/material";

import { styled } from "@mui/material/styles";
import { loginUser, useUserDispatch } from "../../context/UserContext";
import { resetUserPassword } from "../../app/resetPasswordSlice";
import { usePageTracking } from "../../usePageTracking";
import { getErrorFormattedMessage } from "../../util/util";

// import useStyles from "./newstyles";
import RebrandingBanner from "../../components/RebrandingBanner/RebrandingBanner";

// import backgroundImage from "./turing-backdrop.png";
import logo from "./Turing_Logo_Horizontal-Color_on_Dark.png";
// import logoLight from "./Turing_Logo_Horizontal-Color_on_Light.png";
import TopClearLogo from "./Logo-TOP Clear Blue.png";

const StyledBox = styled(Box)(({ theme }) => ({
  minHeight: "100vh",
  display: "flex",
  flexDirection: "column",
  position: "relative", // Make it the containing block for absolutely positioned children
  overflow: "hidden", // Ensure content stays within bounds
  // background: `linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.5)), url(${backgroundImage})`,
  // backgroundSize: "cover",
  // backgroundPosition: "center",
}));

const StyledCard = styled(Card)(({ theme }) => ({
  padding: theme.spacing(4),
  width: "100%",
  maxWidth: 400,
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  backgroundColor: "rgba(255, 255, 255, 1)",
  backdropFilter: "blur(10px)",
  boxShadow: "0 8px 32px rgba(0, 0, 0, 0.1)",
  borderRadius: theme.shape.borderRadius * 4,
}));

const Logo = styled("img")({
  width: 145,
  marginLeft: 3,
});

const TCLogo = styled("img")({
  width: 180,
  align: "center",
  //marginBottom: 0,
});

var NewLogin = (props) => {
  // var classes = useStyles();
  var userDispatch = useUserDispatch();
  var [isLoading, setIsLoading] = useState(false);
  var [error, setError] = useState(null);
  var [reseterror] = useState(null);
  var [resetsuccess] = useState(null);
  const [forgotPasswordStatus, setForgotPasswordStatus] = useState(false);
  var [loginValue, setLoginValue] = useState();
  var [passwordValue, setPasswordValue] = useState();
  const [resetPasswordEmail, setResetPasswordEmail] = useState("");
  const [resetPasswordResponse, setResetPasswordResponse] = useState("");
  const [messageOpen, setMessageOpen] = useState(false);
  const [errorOpen, setErrorOpen] = useState(false);
  var [errorMsg] = useState("");
  const [vertical] = React.useState("top");
  const [horizontal] = React.useState("right");

  const dispatch = useDispatch();
  const history = useHistory();

  usePageTracking();

  useEffect(() => {
    document.title = "TOP Clear - Login";
    console.log("Session", sessionStorage.getItem("rebrandingBannerVisible"));
    if (!sessionStorage.getItem("rebrandingBannerVisible")) {
      sessionStorage.setItem("rebrandingBannerVisible", "true");
    }
  }, []);

  const resetPasswordStatus = useSelector(
    (state) => state.resetPasswordSlice.resetPasswordStatus
  );

  const resetPasswordError = useSelector(
    (state) => state.resetPasswordSlice.resetPasswordError
  );

  useEffect(() => {
    if (resetPasswordStatus && isLoading) {
      setResetPasswordResponse(resetPasswordStatus);
      setErrorOpen(false);
      setMessageOpen(true);
      setForgotPasswordStatus(false);
      setIsLoading(false);
    }
    if (resetPasswordError && isLoading) {
      const errorMessage = getErrorFormattedMessage(resetPasswordError);
      setResetPasswordResponse(errorMessage);
      setMessageOpen(false);
      setErrorOpen(true);
      setIsLoading(false);
    }
    // eslint-disable-next-line
  }, [resetPasswordStatus, resetPasswordError]);

  const handleLogin = async () => {
    if (loginValue.length > 0 && passwordValue.length > 0) {
      setIsLoading(true);
      try {
        await loginUser(
          userDispatch,
          loginValue,
          passwordValue,
          history,
          setIsLoading,
          setError
        );
      } catch (err) {
        setError("Login failed. Please try again.");
        setIsLoading(false);
      }
    }
  };

  const handleResetPassword = () => {
    dispatch(resetUserPassword({ email: resetPasswordEmail }));
    setIsLoading(true);
  };

  return (
    <StyledBox>
      <RebrandingBanner className="loginBanner" />
      <AppBar position="static" color="transparent" elevation={0}>
        <Logo style={{ margin: "24px" }} src={logo} alt="Turing" />
      </AppBar>
      <Box
        flex={1}
        display="flex"
        justifyContent="center"
        alignItems="center"
        // minHeight="80vh"
        padding={2}
        elevation={5}
      >
        {/* Video background */}
        <video
          autoPlay
          loop
          muted
          playsInline
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            width: "100vw",
            height: "100vh",
            objectFit: "cover", // Ensures the video fills the screen
            transform: "translate(-50%, -50%)",
            zIndex: -1,
          }}
        >
          <source
            src={`assets/video/turingbackdrop-video.mp4`}
            type="video/mp4"
          />
          Your browser does not support the video tag.
        </video>
        <StyledCard>
          <AppBar
            style={{ alignItems: "center" }}
            position="static"
            color="transparent"
            elevation={0}
          >
            <TCLogo src={TopClearLogo} alt="Turing" />
          </AppBar>
          <Typography
            variant="subtitle1"
            gutterBottom
            color="textSecondary"
          ></Typography>
          <Divider flexItem sx={{ margin: 2 }} />
          {!forgotPasswordStatus ? (
            <>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                placeholder="Email Address"
                autoFocus
                value={loginValue}
                InputLabelProps={{ shrink: true }}
                onChange={(e) => setLoginValue(e.target.value)}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                //name="password"
                label="Password"
                type="password"
                id="password"
                placeholder="Password"
                autoComplete="current-password"
                value={passwordValue}
                InputLabelProps={{ shrink: true }}
                onChange={(e) => setPasswordValue(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    handleLogin();
                  }
                }}
              />
              <Button
                fullWidth
                variant="contained"
                color="primary"
                size="large"
                onClick={handleLogin}
                disabled={isLoading}
                sx={{ mt: 2, mb: 2 }}
              >
                {isLoading ? <CircularProgress size={24} /> : "Sign In"}
              </Button>
              <Divider flexItem sx={{ margin: 2 }} />
              <Button
                color="primary"
                onClick={() => setForgotPasswordStatus(true)}
              >
                Forgot Password?
              </Button>
              {/* <h6>Powered By Turing</h6> */}
            </>
          ) : (
            <>
              <Typography variant="body1" align="center" gutterBottom>
                Enter your email address to reset your password
              </Typography>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="resetPasswordEmail"
                label="Email Address"
                name="email"
                autoComplete="Username"
                value={resetPasswordEmail}
                onChange={(e) => setResetPasswordEmail(e.target.value)}
              />
              <Button
                fullWidth
                variant="contained"
                color="primary"
                size="large"
                onClick={handleResetPassword}
                disabled={isLoading || resetPasswordEmail.length === 0}
                sx={{ mt: 2, mb: 2 }}
              >
                {isLoading ? <CircularProgress size={24} /> : "Send Reset Link"}
              </Button>
              <Button
                color="primary"
                onClick={() => setForgotPasswordStatus(false)}
              >
                Back to Login
              </Button>
            </>
          )}
          {/* <Box
            width="100%"
            display="flex"
            flexDirection={{ xs: "column", lg: "row" }}
            justifyContent="center"
            alignItems="center"
            px={1.5}
            sx={{paddingTop: "50px"}}
          >
            <Box
              component="ul"
              sx={({ breakpoints }) => ({
                display: "flex",
                flexWrap: "wrap",
                alignItems: "center",
                justifyContent: "center",
                listStyle: "none",
                mt: 3,
                mb: 0,
                p: 0,
                fontSize: "0.6rem",
                // padding: "40px 5px 0px 5px",
                [breakpoints.up("lg")]: {
                  mt: 0,
                },
              })}
            >
               Powered By <Logo style={{ padding: "10px 5px 10px 5px", height: "17%", width:"17%" }} src={logoLight} alt="Turing" />
            </Box>
          </Box> */}
        </StyledCard>
      </Box>

      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={error !== null}
        autoHideDuration={3000}
        onClose={() => setError(null)}
      >
        <Alert severity="error" onClose={() => setError(null)}>
          {error}
        </Alert>
      </Snackbar>
      {/* <Snackbar anchorOrigin={{ vertical, horizontal }} open={enterpriseError} autoHideDuration={3000}>
          <Alert severity="error">
            Not a valid Enterprise account
          </Alert>
      </Snackbar>    */}

      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={resetsuccess}
        autoHideDuration={3000}
      >
        <Alert severity="success">Reset Password Email Sent Successfully</Alert>
      </Snackbar>

      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={reseterror}
        autoHideDuration={3000}
      >
        <Alert severity="error">{errorMsg}</Alert>
      </Snackbar>

      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={messageOpen}
        autoHideDuration={3000}
        onClose={() => setMessageOpen(false)}
      >
        <Alert severity="success">{resetPasswordResponse}</Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={errorOpen}
        autoHideDuration={3000}
        onClose={() => setErrorOpen(false)}
      >
        <Alert severity="error">{resetPasswordResponse}</Alert>
      </Snackbar>
    </StyledBox>
  );
};

export default NewLogin;
