import React, { useState, useEffect, useMemo } from "react";
import { Box, Chip, Drawer, IconButton, List } from "@mui/material";
import { withRouter } from "react-router-dom";
import classNames from "classnames";
import * as Icons from "@mui/icons-material";
import useStyles from "./styles";
import SidebarLink from "./components/SidebarLink/SidebarLink";
import DashboardIcon from "@mui/icons-material/Dashboard";
import AddIcon from "@mui/icons-material/Add";
import { useDispatch, useSelector } from "react-redux";

import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from "../../context/LayoutContext";
import { whitelabelConfig } from "../../whitelabelConfig/whitelabelConfig";
import { getcustomDashboards } from "../../app/customDashboardsSlice";

const structure = [
  { id: 0, type: "divider" },
  { id: 1, label: "Dashboard", link: "/app/dashboard", icon: <Icons.Home /> },
  {
    id: 2,
    label: "Alarms",
    link: "/app/alarms",
    icon: <Icons.NotificationsActive />,
  },
  {
    id: 7,
    label: "Diagnostics",
    link: "/app/diagnostics",
    icon: <Icons.Assessment />,
  },
  {
    id: 3,
    label: "Trigger Settings",
    link: "/app/triggers",
    icon: <Icons.Mail />,
  },
  {
    id: 4,
    label: "Processed Data",
    link: "/app/processedData",
    icon: <Icons.Book />,
  },
  {
    id: 5,
    label: "Device Management",
    link: "/app/deviceManagement/manageDevices",
    icon: <Icons.Settings />,
  },
  {
    id: 6,
    label: "Metering Dashboard",
    link: "/app/meteringDashboard",
    icon: <Icons.Speed />,
  },
  // {
  //   id: 8,
  //   label: "Assets Dashboard",
  //   link: "/app/assetsDashboard",
  //   icon: <Icons.DeviceHub />,
  // },
  // {
  //   id: 9,
  //   label: "Assets Management",
  //   link: "/app/assetsManagement",
  //   icon: <Icons.TableChart />,
  // },
  { id: 10, type: "divider" },
  // { id: 6, type: "divider" },
  // { id: 6, label: "Custom Dashboard", link: "/app/customDashboards", icon: <Icons.Widgets /> },
];

function Sidebar({ location }) {
  var classes = useStyles();

  const dispatch = useDispatch();
  const organization = sessionStorage.getItem("organization");
  const username = sessionStorage.getItem("email");
  const currentOwner = organization + "-" + username;
  const customDashboards = useSelector(
    (state) => state.customDashboards.customDashboards
  );
  const dashboardSettings = useSelector(
    (state) => state.configurationSlice.dashboardConfig
  );

  // global
  var { isSidebarOpened } = useLayoutState();
  var layoutDispatch = useLayoutDispatch();
  var layoutState = useLayoutState();
  const [customDashboardList, setcustomDashboardList] = useState(null);

  const [isRemoteeyeUser, setIsRemoteeyeUser] = useState(false);

  // local
  const [isPermanent, setPermanent] = useState(true);
  const [logoImage, setlogoImage] = useState(null);
  const [logoWidth, setlogoWidth] = useState("auto");
  const [logoHeight, setlogoHeight] = useState(40);
  const [isMeteringDashboard, setIsMeteringDashboard] = useState(false);
  const siteDomain = sessionStorage.getItem("siteDomain");
  const isAdmin = sessionStorage.getItem("isAdmin") === "true";

  useEffect(() => {
    if (
      whitelabelConfig[siteDomain] &&
      whitelabelConfig[siteDomain] !== undefined
    ) {
      setlogoImage(whitelabelConfig[siteDomain].logo);
      setlogoHeight(whitelabelConfig[siteDomain].logoHeight / 2);
      setlogoWidth(whitelabelConfig[siteDomain].logoWidth / 2);
    } else {
      setlogoImage(`${process.env.PUBLIC_URL}/icon.png`);
      setlogoHeight(40);
      setlogoWidth("auto");
    }
  }, [siteDomain]);

  useEffect(function () {
    window.addEventListener("resize", handleWindowWidthChange);
    handleWindowWidthChange();
    return function cleanup() {
      window.removeEventListener("resize", handleWindowWidthChange);
    };
  });

  useEffect(() => {
    if (customDashboards) {
      setcustomDashboardList(customDashboards);
    }
  }, [customDashboards]);

  useEffect(() => {
    dispatch(getcustomDashboards({ organization }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  let CustomDashboard = {
    id: 6,
    label: "Custom Dashboards",
    icon: <DashboardIcon />,
    children: [],
  };

  if (isAdmin) {
    CustomDashboard.children.push({
      id: 999,
      label: "Add New Dashboard",
      link: `/app/CustomDashboards/new`,
      icon: <AddIcon />,
    });
  }
  if (customDashboardList) {
    if (CustomDashboard.children) {
      CustomDashboard.children = CustomDashboard.children.concat(
        customDashboardList
          .filter((d) => {
            if (d.data?.v2) {
              return false;
            } else if (d.public) {
              return true;
            } else {
              if (d.owner === currentOwner) {
                return true;
              } else {
                return false;
              }
            }
          })
          .map((d) => {
            return {
              id: d.id,
              label: d.name,
              link: `/app/CustomDashboards/${d.key}`,
              icon: <DashboardIcon />,
            };
          })
      );
    }
  }

  let CustomDashboardNew = {
    id: 12,
    label: "Custom Dashboards",
    icon: <DashboardIcon />,
    children: [],
  };

  if (isAdmin) {
    CustomDashboardNew.children.push({
      id: 955,
      label: "Add New Dashboard",
      link: `/app/CustomDashboardsNew/new`,
      icon: <AddIcon />,
    });
  }
  if (customDashboardList) {
    if (CustomDashboardNew.children) {
      CustomDashboardNew.children = CustomDashboardNew.children.concat(
        customDashboardList
          .filter((d) => {
            if (!d.data?.v2) {
              return false;
            } else if (d.public) {
              return true;
            } else {
              if (d.owner === currentOwner) {
                return true;
              } else {
                return false;
              }
            }
          })
          .map((d) => {
            return {
              id: d.id,
              label: d.name,
              link: `/app/CustomDashboardsNew/${d.key}`,
              icon: <DashboardIcon />,
            };
          })
      );
    }
  }

  const renderSidebar = useMemo(() => {
    let structureList = structure;
    if (!isRemoteeyeUser) {
      structureList = structureList.filter((link) => link.id !== 7);
    }
    if (!isMeteringDashboard) {
      structureList = structureList.filter((link) => link.id !== 6);
    }

    if (isAdmin) {
      return structureList.map((link, i) => (
        <SidebarLink
          key={i}
          location={location}
          isSidebarOpened={isSidebarOpened}
          {...link}
        />
      ));
    }
    return structureList
      .filter((link) => link.id !== 3)
      .map((link, i) => (
        <SidebarLink
          key={i}
          location={location}
          isSidebarOpened={isSidebarOpened}
          {...link}
        />
      ));
  }, [
    isAdmin,
    isMeteringDashboard,
    isRemoteeyeUser,
    isSidebarOpened,
    location,
  ]);

  useEffect(() => {
    setIsRemoteeyeUser(
      dashboardSettings?.meta_data?.custom_config?.is_remoteeye_user?.value ||
        false
    );
    setIsMeteringDashboard(
      dashboardSettings?.meta_data?.custom_config?.metering_dashboard_enabled
        ?.value || false
    );
  }, [dashboardSettings]);

  return (
    <Drawer
      variant={isPermanent ? "permanent" : "temporary"}
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: isSidebarOpened,
        [classes.drawerClose]: !isSidebarOpened,
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: isSidebarOpened,
          [classes.drawerClose]: !isSidebarOpened,
        }),
      }}
      sx={{
        "& .MuiDrawer-paper": {
          background:
            "linear-gradient(200deg,rgba(25,25,25, 1), rgba(0,25,113, 1))",
        },
      }}
      open={isSidebarOpened}
    >
      {/* <div className={classes.toolbar} />
      <div className={classes.mobileBackButton}>
        <IconButton onClick={() => toggleSidebar(layoutDispatch)}>
          <ArrowBackIcon
            classes={{
              root: classNames(classes.headerIcon, classes.headerIconCollapse),
            }}
          />
        </IconButton>
      </div> */}
      <div style={{ padding: "8px" }} className={classes.mobileBackButton}>
        <IconButton
          size="large"
          color="inherit"
          onClick={() => toggleSidebar(layoutDispatch)}
          className={classNames(
            classes.headerMenuButton,
            classes.headerMenuButtonCollapse
          )}
        >
          {layoutState.isSidebarOpened ? (
            <Icons.Close
              classes={{
                root: classNames(
                  classes.headerIcon,
                  classes.headerIconCollapse
                ),
              }}
              style={{ color: "#00FFEB" }}
            />
          ) : (
            <Icons.Dehaze
              classes={{
                root: classNames(
                  classes.headerIcon,
                  classes.headerIconCollapse
                ),
              }}
              style={{ color: "#00FFEB" }}
            />
          )}
        </IconButton>
      </div>
      {isSidebarOpened === true ? (
        <div style={{ marginTop: "5%" }}>
          <img
            style={{ marginLeft: "6%", width: "auto" }}
            alt="icon"
            src={logoImage}
            width={logoWidth}
            height={logoHeight}
          />
          {/* <Typography
            style={{
              marginTop: "5%",
              marginLeft:"8%",
              color:"white",
              fontFamily: "Poppins",
              fontWeight: "bold",
            }}
          >
            Hi, {sessionStorage.getItem("first_name")}
          </Typography> */}
        </div>
      ) : null}
      <List
        sx={{ padding: "8px", marginTop: "10px" }}
        className={classes.sidebarList}
      >
        {renderSidebar}
        {CustomDashboard && (
          <SidebarLink
            key={CustomDashboard.id}
            location={location}
            isSidebarOpened={isSidebarOpened}
            // openbyDefault={true}
            {...CustomDashboard}
          />
        )}
        <Box position="relative">
          <Chip
            label="BETA"
            size="small"
            color="warning"
            sx={{ position: "absolute", zIndex: 1 }}
          />
          {CustomDashboardNew && (
            <SidebarLink
              key={CustomDashboardNew.id}
              location={location}
              isSidebarOpened={isSidebarOpened}
              // openbyDefault={true}
              {...CustomDashboardNew}
            />
          )}
        </Box>
      </List>
    </Drawer>
  );

  // ##################################################################
  function handleWindowWidthChange() {
    var windowWidth = window.innerWidth;
    //var breakpointWidth = theme.breakpoints.values.md;
    var isSmallScreen = windowWidth < 1440;

    if (isSmallScreen && isPermanent) {
      setPermanent(false);
    } else if (!isSmallScreen && !isPermanent) {
      setPermanent(true);
    }
  }
}

export default withRouter(Sidebar);
